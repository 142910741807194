import calendar from '@js/calendar'

export function select_day(day) {
  let solarDayArr = day.split('-');
  let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2]);
  lunarDay.IYearCn=number_chinese(lunarDay.lYear)
  lunarDay.IDateCn=lunarDay.IYearCn+'年·'+lunarDay.IMonthCn +'·'+ lunarDay.IDayCn
  return lunarDay
}

export function number_chinese(str) {
  str=(String)(str)
  return str.replace(/0/g, '零').replace(/1/g, '壹').replace(/2/g, '贰').replace(/3/g, '叁').replace(/4/g, '四').replace(/5/g, '伍').replace(/6/g, '六')
      .replace(/7/g, '柒').replace(/8/g, '捌').replace(/9/g, '玖')

}