import http from "@u/http";

export function schedule_save(params) {
  return http.post('/apis/memo_pad/schedule_edit',params,'/testApi')
}

export function schedule_list(params) {
  return http.post('/apis/memo_pad/schedule_list',params,'/testApi')
}
export function schedule_detail(params) {
  return http.post('/apis/memo_pad/schedule_detail',params,'/testApi')
}

export function del_schedule(params) {
  return http.post('/apis/memo_pad/del_schedule',params,'/testApi')
}