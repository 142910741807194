<template>
    <div class="my-header between p10">
        <div class="back text-center" @click="$router.go(-1)">
            <el-icon><arrow-left-bold /></el-icon>
        </div>
        <div class="title">{{title}}</div>
        <div class="right" @click="jump">{{right_title}}</div>
    </div>
</template>

<script>
  import {ArrowLeftBold} from '@element-plus/icons'
  export default {
    name: "public_header",
    components:{
      ArrowLeftBold
    },
    props:{
      title:{
        type:String,
        default:''
      },
      right_title:{
        type:String,
        default:''
      },
      jump_url:{
        type:String,
        default:''
      }
    },

    methods:{
      jump() {
        if(this.jump_url!=''){
          this.$router.push(this.jump_url)
        }
      }
    }
  }
</script>

<style scoped>
    .my-header{
        line-height: 30px;
        height: 50px;
        background-color: white;
    }
    .my-header .back{
        width: 30px;
        border-radius: 50%;
        background-color: #999;
        font-size: 15px;
        color: white;
    }
    .my-header .title{
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }
    .my-header .right{
        min-width: 20px;
        font-size: 15px;
        color: #333;
    }
</style>